import React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import "./Footer.sass";
import useScript from "./hooks/useScript";

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  useScript("https://www.fbgcdn.com/embedder/js/ewm2.js");

  return (
    <div className="Footer">
      <div>&copy; {currentYear} Pizza Held</div>
      <div>
        Made with <FavoriteIcon /> by{" "}
        <a
          href="https://www.phil-harmonie.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          Phil Harmonie Mediendesign
        </a>
      </div>
      <div>
        <span
          sytle={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
          data-glf-cuid="ced1f3d4-8747-4677-a52d-76b8d0fad6c2"
          data-glf-ruid="dd3eff3c-c0ce-4f03-8cce-f1bb26a5ae3f"
        >
          Jetzt bestellen
        </span>
      </div>
    </div>
  );
}

export default Footer;
