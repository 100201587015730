import React from "react";
import "./Landing.sass";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { Animated } from "react-animated-css";
import useScript from "./hooks/useScript";

import logo from "./logo.png";

export default function Landing() {
  useScript("https://www.fbgcdn.com/embedder/js/ewm2.js");

  return (
    <div className="Landing">
      <img className="logo" src={logo} alt="Pizza Held" />
      <h1>Willkommen bei Pizza Held</h1>
      <Animated
        animationIn="bounceInLeft"
        animationOut="fadeOut"
        isVisible={true}
      >
        <Button
          className="glf-button"
          data-glf-cuid="ced1f3d4-8747-4677-a52d-76b8d0fad6c2"
          data-glf-ruid="dd3eff3c-c0ce-4f03-8cce-f1bb26a5ae3f"
          variant="contained"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          <AddShoppingCartIcon />
          Jetzt bestellen
        </Button>
      </Animated>
    </div>
  );
}
