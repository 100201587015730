import React from "react";
import "./Navigation.sass";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <nav className="mainnav">
      <Link to="/" className="company">
        Pizza Held
      </Link>
      <ul>
        <li>
          <Link
            to="/"
            data-glf-cuid="ced1f3d4-8747-4677-a52d-76b8d0fad6c2"
            data-glf-ruid="dd3eff3c-c0ce-4f03-8cce-f1bb26a5ae3f"
          >
            Jetzt bestellen
          </Link>
        </li>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
        <li>
          <Link to="/datenschutz">Datenschutz</Link>
        </li>
      </ul>
    </nav>
  );
}
