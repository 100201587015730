import React from "react";
import "./App.sass";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from "./Navigation";
import Landing from "./Landing";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Router path="/impressum">
            <Impressum />
          </Router>
          <Router path="/datenschutz">
            <Datenschutz />
          </Router>
          <Route exact path="" component={Landing} />
          <Route exact path="/" component={Landing} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
