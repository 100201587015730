import React from "react";

function Impressum() {
  return (
    <div className="Impressum" style={{ padding: "2rem", minHeight: "100%" }}>
      <h1>Impressum</h1>
      <div className="space"></div>
      <h2>Diensteanbieter</h2>
      Pizza Held
      <br />
      Am Festplatz 18
      <br />
      35435 Wissmar
      <br />
      <div className="space"></div>
      <h2>Kontaktmöglichkeiten</h2>
      E-Mail-Adresse:
      <a href="mailto:info@pizza-held-wissmar.de">info@pizza-held-wissmar.de</a>
      <br />
      Telefon: 06406 9092424
      <div className="space"></div>
      <h2>Bildnachweise</h2>
      <p>
        <strong>Bildquellen und Urheberrechtshinweise:</strong>
      </p>
      <p>
        <a
          href="https://unsplash.com/@yu_salad"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Unsplash @ Yu Salad
        </a>
        <br />
        Phil Harmonie Mediendesign & Pizza Held
      </p>
    </div>
  );
}

export default Impressum;
